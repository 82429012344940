
import { defineComponent } from 'vue';
import AuthService from '@/services/AuthService';
declare const bootstrap: any;

export default defineComponent({
  name: 'app',
  data () {
    return {
      authService: new AuthService(),
      authUrl: process.env.VUE_APP_AUTH,
      logoutUrl: process.env.VUE_APP_AUTH_LOGOUT
    }
  },
  mounted () {
    if (this.authService.IsExpired()) {
      this.authService.LogOut();
      window.open(this.authUrl, '_self');
    }
  },
  methods: {
    closeNav () {
      var menu = document.getElementById('navbarSupportedContent');
      if (menu != null) {
        new bootstrap.Collapse(menu).toggle();
      }
    },
    loggedIn (): boolean {
      return this.authService.IsLoggedIn();
    },
    logOut () {
      this.authService.LogOut();
      window.open(this.logoutUrl, '_self');
    },
    userLoggedIn () {
      this.$forceUpdate();
    }
  }
});
