
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  data () {
    return {
      authUrl: process.env.VUE_APP_AUTH
    }
  }
})
